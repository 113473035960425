import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import Containers from './icons/containers';
import Moving from './icons/moving';
import Storage from './icons/storage';

const Solutions = ({
  moving = [
    <React.Fragment key="moving">
      <span className="text-brand-orange">Move</span>
      <span> with us</span>
    </React.Fragment>,
  ],
  storage = [
    <React.Fragment key="storage">
      <span className="text-brand-orange">Store</span>
      <span> with us</span>
    </React.Fragment>,
  ],
  containers = [
    <React.Fragment key="containers">
      <span className="text-brand-orange">Buy</span>
      <span> a container</span>
    </React.Fragment>,
  ],
}) => (
  <div
    id="solutions"
    className="flex flex-wrap w-full px-6 py-4 mt-12 md:-mx-2"
  >
    <div className="flex w-full mt-6 md:px-2 md:mt-0 md:w-1/3 first:mt-0">
      <Link
        to="https://www.frontlineremovals.com.au/furniture-removal/"
        className="flex flex-col items-center flex-1 px-6 py-12 text-center rounded bg-brand-blue"
      >
        <Moving className="h-32 text-brand-orange" />
        <h3 className="text-4xl font-semibold leading-none text-white uppercase font-display">
          {moving}
        </h3>
      </Link>
    </div>
    <div className="flex w-full mt-6 md:px-2 md:mt-0 md:w-1/3 first:mt-0">
      <Link
        to="https://www.frontlineremovals.com.au/storage-solutions/"
        className="flex flex-col items-center flex-1 px-6 py-12 text-center rounded bg-brand-blue"
      >
        <Storage className="h-32 text-brand-orange" />
        <h3 className="text-4xl font-semibold leading-none text-white uppercase font-display">
          {storage}
        </h3>
      </Link>
    </div>
    <div className="flex w-full mt-6 md:px-2 md:mt-0 md:w-1/3 first:mt-0">
      <Link
        to="https://www.frontlineremovals.com.au/shipping-containers-for-sale/"
        className="flex flex-col items-center flex-1 px-6 py-12 text-center rounded bg-brand-blue"
      >
        <Containers className="h-32 text-brand-orange" />
        <h3 className="text-4xl font-semibold leading-none text-white uppercase font-display">
          {containers}
        </h3>
      </Link>
    </div>
  </div>
);

Solutions.propTypes = {
  moving: PropTypes.node,
  storage: PropTypes.node,
  containers: PropTypes.node,
};

export default Solutions;
